<template>
  <div class="page home">
    <div class="header header-center">
      <div class="header-body text-center">
        <div class="text-center">
          <h3 class="page-title">Russell Brown Interiors</h3>
          <h1 class="hero-header">Our Privacy Policy</h1>
          <a href="#" v-scroll-to="'#start'">
            <img
              src="/flex-content/uploads/2020/11/downarrow.svg"
              class="down"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="page-body" id="start">
      <intersect
        :threshold="[0.25, 0.25]"
        @enter="bigNav(false)"
        @leave="bigNav(true)"
      >
        <div class="page-body-inner">
          <section class="col">
            <div class="l-section-h i-cf">
              <h3>DATA PROTECTION PRIVACY POLICY</h3>
              <p>
                This Privacy Policy (the Policy) was updated on 24 DEC 2020. It
                describes how Russell Brown Interiors collects, uses and shares
                the information you provide to us and the information we collect
                in the course of operating our business and our website.
              </p>
              <p>
                In this Policy when we refer to "we”/“us”/“our” we mean Russell
                Brown Interiors.
              </p>
              <p>
                We may revise this Policy at any time by amending this page. You
                are expected to check this page from time to time to take notice
                of any changes we make, as they are binding on you.
              </p>
              <h3>THIS POLICY DESCRIBES:</h3>
              <ul>
                <li>The information that we collect</li>
                <li>How we use the information we collect</li>
                <li>How we share information with third parties</li>
                <li>Keeping your information and information security</li>
                <li>Your choices and rights</li>
                <li>Cookies</li>
                <li>How to contact us and other important information</li>
              </ul>
              <h3>THE INFORMATION THAT WE COLLECT AND WHERE WE GET IT FROM</h3>
              <p>
                “Personal information” is any information that can be used to
                identify you or that we can link to you and which we have in our
                possession or control.
              </p>
              <p>
                We will collect and process the following personal information
                about you:
              </p>
              <h3>INFORMATION THAT YOU PROVIDE TO US</h3>
              <p>
                We will process personal information that you give to us
                including when you email us or contact us through various
                channels as follows:
              </p>
              <ul>
                <li>
                  Signing up for services on our website: when you sign up for
                  newsletters, events or enquire about a new project or
                  products; or when you contact us with enquiries or respond to
                  our communications, the personal information you provide may
                  include your full name, telephone number, email address and
                  information about your home or your business.
                </li>
                <li>
                  In connection with our provision of projects and products: As
                  a partner or client, you provide us with personal information
                  when you or the company you represent enquires about doing
                  business together or entering into a contractual agreement
                  with us. If you are not a direct business partner or client,
                  then we may collect or receive your personal information
                  because you are involved in one of our business matters.
                </li>
                <li>
                  Social media:we may contact you directly via LinkedIn, Twitter
                  and other social media sites to obtain your email address.
                  This is so that we can conduct a business discussion with you
                  relating to a project you want to start or our products. We
                  will not send you marketing mailings unless you have consented
                  for us to do so.
                </li>
              </ul>
              <h3>INFORMATION WE OTHERWISE COLLECT ABOUT YOU</h3>
              <p>
                We will also collect information about you when you use our
                services or when we otherwise interact or correspond with you.
                We use various technologies to collect and store information
                when you visit our websites. We may, for example, collect
                information about the type of device you use to access the
                websites, your IP address and your geographic location, the
                operating system and version, your browser type, the content you
                view and features you access on our websites, the web pages and
                the search terms you enter on our websites. For information
                about how we use Cookies and the choices you may have.
              </p>
              <p>&nbsp;</p>
              <h3>HOW WE USE THE INFORMATION WE COLLECT</h3>
              <p>
                Depending on the purposes for which we have collected the
                information, we may do the following with your personal
                information;
              </p>
              <ul>
                <li>
                  use it to engage in marketing and business development
                  activity in relation to our projects and products. This may
                  include sending you emails about our business activity and
                  invitations to events.
                </li>
                <li>
                  business referral to an interested party looking to use your
                  business products or services.
                </li>
                <li>
                  to comply with legal and regulatory obligations that we have
                  to discharge
                </li>
                <li>
                  record and monitor your use of our websites or our other
                  online services for our business purposes which may include
                  analysis of usage, measurement of site performance and
                  generation of marketing reports
                </li>
                <li>
                  use it for our legitimate business interests, such as
                  undertaking business research and analysis, managing the
                  operation of our websites and our business
                </li>
                <li>
                  use it to look into any complaints or queries you may have,
                  and
                </li>
                <li>
                  use it to prevent and respond to actual or potential fraud or
                  illegal activities.
                </li>
              </ul>
              <h3>GROUNDS FOR USING YOUR PERSONAL INFORMATION</h3>
              <p>
                We rely on the following legal grounds to process your personal
                information, namely:
              </p>
              <ul>
                <li>
                  Consent– we may (but usually do not) need your consent to use
                  your personal information. Where we rely on your consent, you
                  can withdraw your consent by contacting us (see below).
                </li>
                <li>
                  Performance of a contract– we may need to collect and use your
                  personal information to enter into a contract with you or to
                  perform our obligations under a contract with you.
                </li>
                <li>
                  Legitimate interest– we may use your personal information for
                  our legitimate interests, some examples of which are given
                  above.
                </li>
                <li>
                  Compliance with law or regulation– we may use your personal
                  information as necessary to comply with applicable
                  law/regulation.
                </li>
              </ul>
              <h3>HOW WE SHARE INFORMATION WITH THIRD PARTIES</h3>
              <p>
                We share personal information with our data processors, such as
                accounting software platform, payroll bureau, online content
                management system, email platform, shared server, online file
                server, website provider and third parties providing business
                services to us. As a result, your personal information may be
                transferred to locations outside Europe as well as within it for
                the purposes described above.
              </p>
              <p>
                Where we transfer your personal information outside Europe, we
                will ensure that it is protected and transferred in a manner
                consistent with legal requirements applicable to the
                information. This can be done in a number of different ways, for
                instance:
              </p>
              <ul>
                <li>
                  the country to which we send the personal information may be
                  approved by the European Commission
                </li>
                <li>
                  the recipient may have signed a contract based on “model
                  contractual clauses” approved by the European Commission,
                  obliging them to protect your personal information, or
                </li>
                <li>
                  where the recipient is located in the US, it may be a
                  certified member of the EU-US Privacy Shield scheme.
                </li>
              </ul>
              <p>
                In other circumstances, the law may permit us to otherwise
                transfer your personal information outside Europe. In all cases,
                however, any transfer of your personal information will be
                compliant with applicable data protection law.
              </p>
              <h3>KEEPING YOUR INFORMATION AND INFORMATION SECURITY</h3>
              <p>
                How long we hold your personal information for will vary and
                will depend principally on:
              </p>
              <ul>
                <li>
                  the purpose for which we are using your personal information –
                  we will need to keep the information for as long as is
                  necessary for the relevant purpose, and
                </li>
                <li>
                  legal obligations – laws or regulation may set a minimum
                  period for which we have to keep your personal information.
                </li>
              </ul>
              <p>
                We will ensure that the personal information that we hold is
                subject to appropriate security measures.
              </p>
              <h3>YOUR CHOICES AND RIGHTS</h3>
              <p>
                You have a number of legal rights in relation to the personal
                information that we hold about you and you can exercise your
                rights by contacting us using the details set out below.
              </p>
              <p>These rights may include:</p>
              <ul>
                <li>
                  Obtaining information regarding the processing of your
                  personal information and access to the personal information
                  which we hold about you.
                </li>
                <li>
                  Requesting that we correct your personal information if it is
                  inaccurate or incomplete.
                </li>
                <li>
                  Requesting that we erase your personal information in certain
                  circumstances. Please note that there may be circumstances
                  where you ask us to erase your personal information but we are
                  legally entitled to retain it.
                </li>
                <li>
                  Objecting to, and requesting that we restrict, our processing
                  of your personal information in certain circumstances. Again,
                  there may be circumstances where you object to, or ask us to
                  restrict, our processing of your personal information but we
                  are legally entitled to refuse that request.
                </li>
                <li>
                  In some circumstances, receiving some personal information in
                  a structured, commonly used and machine-readable format and/or
                  requesting that we transmit those information to a third party
                  where this is technically feasible. Please note that this
                  right only applies to personal information which you have
                  provided to us.
                </li>
                <li>
                  Withdrawing your consent, although in certain circumstances it
                  may be lawful for us to continue processing without your
                  consent if we have another legitimate reason (other than
                  consent) for doing so.
                </li>
                <li>
                  Lodging a complaint with the relevant data protection
                  authority, if you think that any of your rights have been
                  infringed by us. We can, on request, tell you which data
                  protection authority is relevant to the processing of your
                  personal information.
                </li>
              </ul>
              <h3>HOW TO CONTACT US AND OTHER IMPORTANT INFORMATION</h3>
              <p>
                If you would like further information on the collection, use,
                disclosure, transfer or processing of your personal information
                or the exercise of any of the rights listed above, please
                contact us:
              </p>
              <ul>
                <li>
                  by email&nbsp;<a href="mailto:enquiries@russell-brown.co.uk"
                    >enquiries@russell-brown.co.uk</a
                  >
                </li>
              </ul>
            </div>
          </section>
        </div>
      </intersect>
    </div>
    <div class="hero-slider">
      <div class="overlay"></div>
      <div class="slideshow">
        <div class="container">
          <div class="slide">
            <transition name="fadeInSlow">
              <div>
                <imgdivloader
                  :classes="'bg AppImage'"
                  :lazySrc="pageData.fimg_url"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <footerdiv />
  </div>
</template>
<script>
import footerdiv from "@/partials/footer.vue";
import imgdivloader from "@/partials/ImgLoaderDiv.vue";
import intersect from "vue-intersect";
export default {
  data() {
    return {
      portfolioData: null,
    };
  },
  components: {
    footerdiv,
    imgdivloader,
    intersect,
  },
  computed: {
    pageData() {
      var routePath = this.$route.fullPath;

      if (routePath === "/") {
        var permaLink = "/";
      } else {
        permaLink = this.$route.path + "/";
      }

      return this.$store.getters.getPage(permaLink);
    },
  },
  mounted() {
    this.bigNav(false);
  },
  methods: {
    bigNav(value) {
      this.$store.commit("TOGGLE_NAVBAR_SIZE", value);
    },
  },
  metaInfo() {
    return {
      title: this.pageData.yoast_meta.yoast_wpseo_title,
      link: [
        {
          rel: "canonical",
          href: "https://russell-brown.co.uk" + this.$route.path,
        },
      ],
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },

        // Twitter Meta Tags
        {
          vmid: "twitter:card",
          name: "twitter:card",
          content: "summary_large_image",
        },

        {
          vmid: "twitter:title",
          name: "twitter:title",
          content: this.pageData.yoast_meta.yoast_wpseo_title,
        },

        {
          vmid: "twitter:image",
          name: "twitter:image",
          content: this.pageData.fimg_url,
        },

        {
          vmid: "twitter:description",
          name: "twitter:description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },
        //Open Graph Integrations
        {
          vmid: "og:image",
          property: "og:image",
          content: this.pageData.fimg_url,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.pageData.yoast_meta.yoast_wpseo_title,
        },
        { vmid: "og:locale", property: "og:locale", content: "en_GB" },
        { vmid: "og:type", property: "og:type", content: "article" },
        { vmid: "og:url", property: "og:url", content: this.pageData.link },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "Russell Brown Interiors",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.pageData.yoast_meta.yoast_wpseo_metadesc,
        },
        {
          vmid: "article:modified_time",
          name: "article:modified_time",
          content: this.pageData.modified_gmt + "+00:00",
        },
        {
          vmid: "article:published_time",
          name: "article:published_time",
          content: this.pageData.date_gmt + "+00:00",
        },
        {
          vmid: "og:updated_time",
          name: "og:updated_time",
          content: this.pageData.modified_gmt + "+00:00",
        },
      ],
    };
  },
};
</script>